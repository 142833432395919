<script setup lang="ts">
import type { Schemas, RequestParameters } from "#shopware";
import type {
  CardFieldsOnApproveData,
  PayPalCardFieldsComponent,
} from "@paypal/paypal-js";

const props = withDefaults(
  defineProps<{
    readyForNextStep?: boolean;
    // in case this is used for repay, the original order must be provided
    repayOrder?: Schemas["Order"];
  }>(),
  {
    readyForNextStep: false,
    repayOrder: undefined,
  },
);

const emit = defineEmits(["update:readyForNextStep"]);

const {
  getPaymentMethods,
  paymentMethods,
  selectedPaymentMethod,
  setPaymentMethod,
} = useCheckout();

const { updateCustomerAddress } = useAddress();

const { setActiveBillingAddress, activeBillingAddress } = useSessionContext();

const {
  loadPayPalNamespace,
  insertFraudNetScripts,
  PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME,
  PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME,
} = usePayPal();
const { apiClient } = useShopwareContext();
const { updatePersonalInfo, user } = useUser();
const handlePaymentExtensions = useHandlePaymentExtensions();

const isLoading = ref(false);

const creditCardFields = ref<PayPalCardFieldsComponent | null>(null);
const htmlNumberFieldContainer = ref<HTMLDivElement>();
const htmlExpityFieldContainer = ref<HTMLDivElement>();
const htmlCVVFieldContainer = ref<HTMLDivElement>();

const birthday = ref("");
const phoneNumber = ref("");

const fraudNetId = ref<string>();

const allPaymentMethods = ref<Schemas["PaymentMethod"][] | null>([]);

const { PAY_PAL_DEFAULT_PAYMENT_HANDLER } = usePayPal();
const PAYMENT_METHOD_FREE_ID = "019392449af373aabfee8332d5c8cdaa";

onMounted(async () => {
  const { data } = await apiClient
    .invoke("readPaymentMethod post /payment-method", {
      body: { onlyAvailable: true },
    })
    .then((response) => ({ data: response.data?.elements || [] }));

  // Puts Paypal in first place, and others below paypal
  const paymentMethodsList = data.sort((a, b) => {
    if (a.shortName === PAY_PAL_DEFAULT_PAYMENT_HANDLER) return -1;
    if (b.shortName === PAY_PAL_DEFAULT_PAYMENT_HANDLER) return 1;
    return 0;
  });
  allPaymentMethods.value = paymentMethodsList as Schemas["PaymentMethod"][];

  const birthdayString = user.value?.birthday || "";
  if (birthdayString) {
    const date = new Date(birthdayString);
    birthday.value = date.toISOString().split("T")[0];
  }
  phoneNumber.value = activeBillingAddress.value?.phoneNumber || "";

  let pm = await getPaymentMethods();
  if (!selectedPaymentMethod.value) {
    setPaymentMethod({
      id: user.value?.defaultPaymentMethod?.id || pm.value?.[0]?.id || "",
    });
  }

  await checkCreditcardFieldsShouldRender(
    selectedPaymentMethod.value?.shortName,
  );
});

// Check if PaymentMethod Exists and one is selected
const isPaymentMethodValid = computed(() => {
  if (!allPaymentMethods.value) return false;

  const methods = allPaymentMethods.value;
  if (methods.length > 1) {
    return methods.some((pm) => pm.id === selectedPaymentMethod.value?.id);
  } else if (methods.length === 1) {
    return (
      methods[0]?.id === PAYMENT_METHOD_FREE_ID ||
      methods[0]?.id === selectedPaymentMethod.value?.id
    );
  }
  return false;
});

watch(
  [selectedPaymentMethod, birthday, phoneNumber],
  ([newSelectedPaymentMethod, newBirthday, newPhone]) => {
    if (
      newSelectedPaymentMethod?.shortName ===
      PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME
    ) {
      if (newBirthday && newPhone) {
        emit("update:readyForNextStep", true);
      } else {
        emit("update:readyForNextStep", false);
      }
    } else {
      // If the selected payment method does not require birthday and phone number
      emit("update:readyForNextStep", true);
    }
  },
);

async function checkCreditcardFieldsShouldRender(
  shortName: string | undefined,
) {
  if (!shortName) return;
  // Credit card was not selected
  if (shortName === PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME) {
    await nextTick();

    emit("update:readyForNextStep", false);

    // cardFileds already exist
    if (creditCardFields.value !== null) return;

    const paypal = await loadPayPalNamespace();
    const fields = paypal.CardFields({
      createOrder: async () => {
        const body: RequestParameters<"payPalCreateOrder"> = {
          product: "acdc",
        };

        // if this is a repay, the orderId must be set
        if (props.repayOrder) {
          body.orderId = props.repayOrder.id;
        }

        const { data: response } = await apiClient.invoke(
          "payPalCreateOrder post /paypal/create-order",
          { body },
        );

        return response?.token;
      },
      onApprove: async (data: CardFieldsOnApproveData) => {
        handlePaymentExtensions.value = { paypalOrderId: data.orderID };
      },
      onError: console.error,
      inputEvents: {
        onChange: (data) => {
          emit("update:readyForNextStep", data?.isFormValid || false);
        },
      },
    });

    const numberField = fields.NumberField({});
    assert(
      htmlNumberFieldContainer.value !== undefined,
      "htmlNumberFieldContainer is available",
    );
    numberField.render(htmlNumberFieldContainer.value);

    const expiryField = fields.ExpiryField({});
    assert(
      htmlExpityFieldContainer.value !== undefined,
      "htmlNumberFieldContainer is available",
    );
    expiryField.render(htmlExpityFieldContainer.value);

    const cvvField = fields.CVVField({});
    assert(
      htmlCVVFieldContainer.value !== undefined,
      "htmlNumberFieldContainer is available",
    );
    cvvField.render(htmlCVVFieldContainer.value);

    creditCardFields.value = fields;
  } else {
    creditCardFields.value = null;
  }
}

watch(selectedPaymentMethod, async (method) => {
  isLoading.value = false;

  await checkCreditcardFieldsShouldRender(method?.shortName);

  ensureFraudNetSessionExistsIfNeeded();
});

async function onBeforeNextStep() {
  if (
    selectedPaymentMethod?.value?.shortName ==
    PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME
  ) {
    await creditCardFields.value?.submit();
  } else if (
    selectedPaymentMethod.value?.shortName ===
    PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME
  ) {
    let date = new Date(birthday.value);

    let birthdayDay = date.getDate();
    let birthdayMonth = date.getMonth() + 1; // Months are zero-based, so we add 1
    let birthdayYear = date.getFullYear();

    assert(user.value !== undefined, "user is set");

    // Only update user birthday when it actually changed
    if (user.value.birthday != birthday.value) {
      await updatePersonalInfo({
        lastName: user.value.lastName || "",
        firstName: user.value.firstName || "",
        company: user.value.company || "",
        salutationId: user.value.salutationId || "",
        birthdayDay: birthdayDay,
        birthdayMonth: birthdayMonth,
        birthdayYear: birthdayYear,
      });
    }

    if (activeBillingAddress?.value) {
      activeBillingAddress!.value!.phoneNumber = phoneNumber.value;
      await setActiveBillingAddress(activeBillingAddress.value);
      await updateCustomerAddress(activeBillingAddress.value);
    } else {
      throw new Error("Active address not valid");
    }

    handlePaymentExtensions.value = {
      payPalPuiFraudnetSessionId: fraudNetId.value,
    };
  }
}
defineExpose({ onBeforeNextStep, isPaymentMethodValid });

await getPaymentMethods();

function generateGUID() {
  // return window.crypto.randomUUID(); // only in secure context
  return URL.createObjectURL(new Blob([])).slice(-36).replaceAll("-", "");
}

function ensureFraudNetSessionExistsIfNeeded() {
  if (
    selectedPaymentMethod.value?.shortName ==
      PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME &&
    fraudNetId.value == undefined
  ) {
    fraudNetId.value = generateGUID();
    insertFraudNetScripts(fraudNetId.value, "checkout-page");
  }
}

ensureFraudNetSessionExistsIfNeeded();
</script>

<template>
  <div class="bg-scheppach-shades-0 p-4 mx-auto w-full relative">
    <h1 class="c-scheppach-primary-700 text-[30px] font-700 mb-5">
      {{ $t("checkout.paymentMethodLabel") }}
    </h1>

    <SharedSelectableCardGroup
      class="hidden md:block"
      :selected-card-id="selectedPaymentMethod?.id"
    >
      <template
        v-for="paymentMethod in allPaymentMethods"
        :key="paymentMethod.id"
      >
        <SharedSelectableCard
          :id="paymentMethod.id"
          class="mb-5"
          :disabled="!paymentMethods.find((p) => p.id === paymentMethod.id)"
          :on-click="
            () => [
              setPaymentMethod({ id: paymentMethod.id }),
              (isLoading = true),
            ]
          "
        >
          <div class="flex flex-col gap-5">
            <div class="flex items-center rd hover:cursor-pointer">
              <div class="flex items-center px-5 gap-3 min-w-[250px]">
                <SharedMediaImage
                  v-if="paymentMethod.media"
                  :media="paymentMethod.media"
                  :height="45"
                  class="h-[45px] p-1"
                />
              </div>
              <div class="p-3">
                <h1 class="text-[26px] c-scheppach-primary-500 font-700">
                  {{ paymentMethod.name }}
                </h1>
                <p v-if="selectedPaymentMethod?.id === paymentMethod.id">
                  {{ paymentMethod.description }}
                </p>
              </div>
            </div>
          </div>
        </SharedSelectableCard>
      </template>
      <ScheppachSpinner v-if="isLoading" />
    </SharedSelectableCardGroup>

    <!-- Mobile -->
    <div
      v-for="(paymentMethod, index) in paymentMethods"
      :key="paymentMethod.id"
      :for="paymentMethod.id"
      class="md:hidden flex justify-between items-center b-scheppach-primary-700 px-2 py-1 cursor-pointer"
      :class="[index != paymentMethods.length - 1 ? 'b-b mb-1' : 'b-none mb-0']"
      @click="[setPaymentMethod({ id: paymentMethod.id }), (isLoading = true)]"
    >
      <div class="flex items-center">
        <input
          :id="paymentMethod.id"
          class="payment-radio accent-scheppach-primary-500 w-[22px] h-[22px]"
          :checked="selectedPaymentMethod?.id === paymentMethod.id"
          name="payment-method"
          type="radio"
        />
        <p class="c-scheppach-primary-700 font-500 text-[20px] p-3">
          {{ paymentMethod.name }}
        </p>
      </div>
      <SharedMediaImage
        :media="paymentMethod.media"
        :height="35"
        class="h-[35px]"
      />
      <ScheppachSpinner v-if="isLoading" />
    </div>

    <!-- If Credit/Debit is selected visible, else hidden -->
    <SharedDetailsSummary
      v-if="
        selectedPaymentMethod?.shortName ===
        PAY_PAL_CREDIT_CART_PAYMENT_STORT_NAME
      "
      class="mt-10"
    >
      <template #title>
        <p>
          {{ $t("checkout.creditcardInformations") }}
          <span class="font-500"></span>
        </p>
      </template>
      <div class="p-4 pb-0">
        <div class="grid grid-cols-1 sm:grid-cols-5 gap-3">
          <div
            ref="htmlNumberFieldContainer"
            class="col-span-5 xl:col-span-3"
          ></div>
          <div
            ref="htmlExpityFieldContainer"
            class="col-span-5 sm:col-span-3 xl:col-span-1"
          ></div>
          <div
            ref="htmlCVVFieldContainer"
            class="col-span-5 sm:col-span-2 xl:col-span-1"
          ></div>
        </div>
      </div>
    </SharedDetailsSummary>

    <SharedDetailsSummary
      v-else-if="
        selectedPaymentMethod?.shortName === PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME
      "
      class="mt-10"
    >
      <template #title>
        <p>
          {{ $t("form.requiredHeader") }}
          <span class="font-500"></span>
        </p>
      </template>
      <div class="px-4">
        <div class="py-4">
          <ScheppachTextInput
            v-model="birthday"
            class="pb-5"
            :label="$t('form.birthday')"
            :type="'date'"
          />
          <ScheppachTextInput
            v-model="phoneNumber"
            class=""
            :label="$t('form.phone')"
            :type="'text'"
          />
        </div>
      </div>
    </SharedDetailsSummary>
  </div>
</template>
